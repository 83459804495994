export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awe you suwe?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Today at ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yestewday at ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have pewmission to tawk in this channew."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyat Connected"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a message"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type youw message"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Bottom OWO"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyu Messigu"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Pwofiwe"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Wowes"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Usew:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qwote"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mawk As Wead"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sewvew Settings"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go home"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Emojis"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawtup Options"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progwam Activity"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Bots"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intewface"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanguage"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deete Account"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changewog"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Youw Pwofiwe"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Passwowd"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We-wink Googwe Dive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wink Googwe Dive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mowe Pwofiwe"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This infwowmation is seen by evewyone."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change youw notification settings."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Sound"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makes a coow sound to get youw attention"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notification"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get push notifications even when the app is cwosed."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upwoad youw own pwetty emojis fow fwee! Emojis must be 3MB ow wess."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppowted types awe: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Emojis"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop App Stawtup Options"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To modify these settings, you must downwoad the Newtivia desktop app."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Newtivia On Stawtup"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shawe pwogwams ow games you awe wunning by adding apps bewow. This wiww wepwace youw custom status when a pwogwam is opened and wevewt back when the pwogwam is cwosed."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use the pwogwam acitivty featue, you must downwoad the Newtivia desktop app."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cweate bot usews which wiww awwow you to use ouw API to cweate you own fancy bots."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bot commands to easiwy wet usews know which commands exist fow youw bot"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cweate Bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Command"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Commands"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cweate Invite Wink"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Invite URL"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Token"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Token"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Token"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weset Token"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete Bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You awe about to dewete youw account"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What wiww get deweted"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What wont get deweted"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youw messages sent to pubwic sewvews"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wequiwements"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weave/Dewete aww sewvews"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete aww bots"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete My Account"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contwibute by submitting a PR in the GitHub wepo."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twanswatows"])}
    },
    "interface": {
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEWY Dark"])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crayon fillings"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change how to draw Nertivia~"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOOKY SCAWY SKELETON (2020)"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool colors"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloo Theem"])}
    }
  },
  "server-settings": {
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genewal"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Channews"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Wowes"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Invites"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sewvew Visibiwity"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Notification"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned Usews"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Usews"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete Sewvew"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give people back their apartment keys"])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give keys back"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Throwing keys~~~"])}
    },
    "delete-server": {
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilating~~~"])},
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib name to annihilate >:3"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Sewvew Settins"])}
    },
    "manage-channels": {
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chanl Naem"])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Channl"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilation not allowed!! If u got rid of dis, nobody could talk anymore :("])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilate Chanl"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilating Channel~~~"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Lookin Channal (But shh, it doesn't exist yet)"])},
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manag channnnnels"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Messag"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed Limit (years)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordy-Channle"])}
    },
    "manage-invites": {
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an invitation card for ur frens"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite frendos"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["God-Emperor"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speshul Invite :0"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelda"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only vewy stwong people can look at all invitations."])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down the special invitation"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People invited"])}
    },
    "manage-roles": {
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot Title"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a new Title!"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete Title"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone's Title"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilatin' Title~~~"])},
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book-keep ur Titles."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Title"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No delet!!!! IS BOT! Don't turn people into robots :("])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No delet!!!! IS EVERYBODY!!! U need dis, or it break."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick collor"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look for a frendo"])}
    },
    "notifications": {
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuddup"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Sewvew noisiness"])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell when stuff happens"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shhhh, but still show me when stuff happens"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is all urs UwU"])}
    },
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed thingus"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " is typing..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " and ", _interpolate(_list(1)), " awe typing..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " and ", _interpolate(_list(2)), " awe typing..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " peopwe awe typing..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fwiends"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wecents"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Notes"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Fwiend"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pwofiwe"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificattions"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cwick to add custom status"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothin to see here uwu"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboawd"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diwect Messig"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expwore~"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sewvews"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a fwiend by typing theiw usewname and tag."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sewvew"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a sewvew by entewing in an invite code."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cweate youw own sewvew. Stawt by naming youw sewvew."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cweate"])}
    }
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dewete"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usew"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyfied!!"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bacc"])},
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mjute Chanl umu"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De-mjute chanl owo"])}
  },
  "connection": {
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paw-thenticating~~~"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loadin'~~~"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weady! ÒwÓ"])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updoot"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW UPDOOT!!!"])}
  },
  "presence": {
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gone umu"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buh-zee"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanna pway~"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offwine"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here rn"])}
  },
  "profile-popout": {
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept fren-fren"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add fren"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speshul Medals"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't make fren ùmú"])},
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common frendos :0"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common serverinos"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appeared"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un-fren!!!! >:("])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage, er.. Message"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On a farm upstate"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total frens (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Shleeping (", _interpolate(_list(0)), ")"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make shmol"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to"])}
  }
}